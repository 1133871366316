.base {
  width: 100%;
  height: 100%;
  position: relative;
}

.play_button_wrapper {
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: calc(100% - 67px); // controls 때문에 ...
  position: absolute;
  z-index: 10;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;

  &:hover > div {
    opacity: 0.8;
  }
}

.play_button {
  width: 100px;
  height: 100px;
  transition: opacity 0.2s ease-in-out;
}

.play_img {
  width: 100%;
  height: 100%;
  background-image: url("./../../public/assets/images/PlayCircleFilled.png");
}
